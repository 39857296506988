const AboutService = () => {
    return (
        <div className="service-area">
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-12 col-xl-12 mb-sm-50 mb-md-70"
                        data-aos="fade-up"
                    >
                        <h2 className="title">Welcome!</h2>
                        <p>
                            Welcome to GiBu Gallery, where art lovers and
                            enthusiasts come together to explore a diverse
                            collection of artworks from both local and
                            international artists. Our mission is to support and
                            promote the work of emerging and established
                            artists, providing them with the guidance and
                            support they need to reach their full artistic
                            potential.
                        </p>
                        <p style={{ marginTop: "20px" }}>
                            Our founder, Dr. Gihan Buddhadasa, is a passionate
                            art collector and enthusiast who established the
                            gallery in 2020 to share his personal collection of
                            oil and acrylic paintings and sculptures with
                            others. Since then, the gallery has grown to become
                            a hub for art lovers, collectors, and artists alike,
                            fostering a community that values creativity and
                            artistic expression.
                        </p>
                        <p style={{ marginTop: "20px" }}>
                            At GiBu Gallery, we curate a variety of artwork,
                            including limited original pieces, to cater to
                            individual preferences and budget constraints. We
                            are committed to showcasing the work of talented
                            artists and promoting their unique styles and
                            perspectives. We work closely with our artists,
                            providing them with guidance and advice, to help
                            them grow and succeed in their artistic careers.
                        </p>
                        <p style={{ marginTop: "20px" }}>
                            We take pride in our role as a platform for artists
                            to showcase their talents and for art enthusiasts to
                            discover new and exciting artwork. Our gallery is a
                            safe and welcoming space for everyone, regardless of
                            their artistic background or experience. We invite
                            you to explore our collection and discover the
                            amazing talents of our featured artists. Thank you
                            for choosing GiBu Gallery as your source of art
                            inspiration.
                        </p>
                    </div>
                </div>
                <div style={{ marginTop: "40px" }} className="row">
                    <div
                        className="col-lg-12 col-xl-12 mb-sm-50 mb-md-70"
                        data-aos="fade-up"
                    >
                        <h2 className="title">Vision Statement</h2>
                        <p>
                            GiBu Gallery promotes and supports emerging and
                            established artists in a safe and welcoming space
                            for art lovers and enthusiasts. We showcase unique
                            artwork, foster a vibrant community of collectors,
                            and commit to excellence, creativity, and
                            innovation.
                        </p>
                    </div>
                </div>
                <div
                    style={{ marginTop: "40px", marginBottom: "50px" }}
                    className="row"
                >
                    <div
                        className="col-lg-12 col-xl-12 mb-sm-50 mb-md-70"
                        data-aos="fade-up"
                    >
                        <h2 className="title">Mission Statement</h2>
                        <p>
                            At GiBu Gallery, our mission is to provide a
                            platform for artists to showcase their talents and
                            promote their unique perspectives, while fostering a
                            welcoming community of art enthusiasts. We offer a
                            diverse collection of artworks for all preferences
                            and budget constraints, and strive to create a space
                            where everyone can appreciate the beauty and power
                            of art.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutService;
