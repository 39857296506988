import React, { useEffect, useRef } from "react";

import { Carousel as NativeCarousel } from "@fancyapps/ui";
import "@fancyapps/ui/dist/carousel/carousel.css";

import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";

function Carousel(props) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const options = props.options || {
            Thumbs: {
                type: "modern",
            },
        };

        console.log("C options");
        console.log(options);

        const instance = new NativeCarousel(container, options, { Thumbs });

        return () => {
            instance.destroy();
        };
    });

    return (
        <div
            className="f-carousel"
            style={{
                maxWidth: 728,
                height: 488,
                margin: "0 auto",
                backgroundColor: "#F3F4F6",
            }}
            ref={containerRef}
        >
            {props.children}
        </div>
    );
}

export default Carousel;
