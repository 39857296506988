import React, { useMemo, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";

const ArtDetailsSidebar = ({ data }) => {
    const originalAvailable =
        data?.attributes?.originalAvailability == "Available";

    const printsAvailable = data?.attributes?.prints?.data?.length > 0;

    const noneAvailable = !originalAvailable && !printsAvailable;

    const framesAvailable = data?.attributes?.frames?.data?.length > 0;

    const getInitialPurchaseTypeId = () => {
        if (originalAvailable) {
            return "original";
        }

        if (printsAvailable) {
            return data?.attributes?.prints?.data[0]?.id;
        }

        return "original";
    };

    const getPurchaseTypePriceById = (purchaseTypeId) => {
        if (purchaseTypeId == "original") {
            return data?.attributes?.price ? data?.attributes?.price : 0;
        } else {
            const purchaseType = data?.attributes?.prints?.data.find(
                (print) => print?.id == purchaseTypeId
            );
            return purchaseType?.attributes?.price
                ? purchaseType?.attributes?.price
                : 0;
        }
    };

    const [purchaseTypeId, setPurchaseTypeId] = useState(
        getInitialPurchaseTypeId
    );

    const [purchaseTypePrice, setPurchaseTypePrice] = useState(() =>
        getPurchaseTypePriceById(purchaseTypeId)
    );

    const [frameId, setFrameId] = useState("none");

    const [framePrice, setFramePrice] = useState(0);

    const artists = useMemo(() => {
        if (data?.attributes?.artists.data) {
            return data.attributes.artists.data.map(
                (artist) => artist.attributes.name
            );
        }

        return [];
    }, [data]);

    const onPurchaseTypeChange = (e) => {
        setPurchaseTypeId(e.target.value);

        setPurchaseTypePrice(getPurchaseTypePriceById(e.target.value));
    };

    const onFrameChange = (e) => {
        setFrameId(e.target.value);

        const frameType = data?.attributes?.frames?.data.find(
            (frame) => frame?.id == e.target.value
        );

        setFramePrice(frameType?.attributes?.price);
    };

    const total = (purchaseTypePrice || 0) + (framePrice || 0);

    return (
        <div className="art-details-sidebar">
            <h1 style={{ display: "inline-block" }}>
                {data?.attributes?.name}
            </h1>
            <h6> {artists.join(", ")} </h6>
            <h6>
                <span style={{ fontWeight: "bold" }}>
                    {data?.attributes?.medium}
                </span>{" "}
                | {data?.attributes?.width}cm(W) x {data?.attributes?.height}(H)
            </h6>
            <Form>
                <FormGroup>
                    <div style={{ marginTop: "40px" }}>
                        <Label for="purchaseType">Select Art Work Type</Label>
                        <Input
                            type="select"
                            name="purchaseType"
                            id="purchaseType"
                            value={purchaseTypeId}
                            onChange={onPurchaseTypeChange}
                            onBlur={onPurchaseTypeChange}
                            disabled={noneAvailable}
                        >
                            <option
                                value="original"
                                disabled={!originalAvailable}
                            >
                                {originalAvailable
                                    ? `Original Artwork - A$${data?.attributes?.price}`
                                    : `Original Artwork - A$${data?.attributes?.price} - ${data?.attributes?.originalAvailability}`}
                            </option>
                            {data?.attributes?.prints?.data?.map((print) => (
                                <option key={print?.id} value={print?.id}>
                                    {print?.attributes?.displayName} - A$
                                    {print?.attributes?.price}
                                </option>
                            ))}
                        </Input>
                    </div>
                    <div style={{ marginTop: "40px" }}>
                        <Label for="frame">Select Frame Size</Label>
                        <Input
                            type="select"
                            name="frame"
                            id="frame"
                            value={frameId}
                            onChange={onFrameChange}
                            onBlur={onFrameChange}
                            disabled={!framesAvailable}
                        >
                            <option key="none" value="none" selected>
                                No Frame - A$0
                            </option>
                            {data?.attributes?.frames?.data?.map((frame) => (
                                <option key={frame?.id} value={frame?.id}>
                                    {frame?.attributes?.name} - A$
                                    {frame?.attributes?.price}
                                </option>
                            ))}

                            {!framesAvailable && (
                                <option
                                    key="notAvailable"
                                    value="notAvailable"
                                    disabled
                                    selected
                                >
                                    No Frames Available
                                </option>
                            )}
                        </Input>
                    </div>
                </FormGroup>
            </Form>
            <h4 style={{ marginTop: "40px" }}>
                <span style={{ fontWeight: "bold" }}>Total : A${total}</span>
                {noneAvailable && <span> (Not Available)</span>}
            </h4>
            <h6 style={{ marginTop: "20px" }}>
                {" "}
                {data?.attributes?.freeShippingAndReturns ? (
                    <span>Free shipping and returns</span>
                ) : (
                    ""
                )}
                {data?.attributes?.strechedAndHang ? (
                    <span> | Stretched and ready to hang</span>
                ) : (
                    ""
                )}
            </h6>
        </div>
    );
};

export default ArtDetailsSidebar;
