import React from "react";
import Carousel from "../fancyapps/carousel";
import Fancybox from "../fancyapps/fancybox";

function ProductGallery({ items }) {
    return (
        <Fancybox
            options={{
                Carousel: {
                    infinite: false,
                },
                Images: {
                    Panzoom: {
                        maxScale: 4,
                    },
                    protected: true,
                },
            }}
        >
            <Carousel>
                {items &&
                    items.map((item) => (
                        <div
                            key={item.original}
                            className="f-carousel__slide"
                            data-fancybox="gallery"
                            data-src={item.original}
                            data-thumb-src={item.original}
                        >
                            <img
                                alt=""
                                src={item.original}
                                width="100%"
                                height="100%"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                    ))}
            </Carousel>
        </Fancybox>
    );
}

export default ProductGallery;
