import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";
import BlogItem from "../../../components/blog/blog-item";
import Loader from "../../../components/loader/index";
import { getItemDimensionsDescription, slugify } from "../../../utils";

const url = "https://gibu-art-be-f8dd06baa9af.herokuapp.com/api";

const fetchItems = async (resource, categoryId) => {
    const queryObj = {
        populate: "*",
        sort: "rank:asc",
    };

    if (categoryId) {
        queryObj.filters = {
            categories: {
                id: categoryId,
            },
        };
    }

    const queryString = qs.stringify(queryObj);

    const config = {
        headers: {
            Authorization:
                "Bearer 093f4cb2e504a184e2760b8b89dc19d7bc4053359dfc5ed393ade381ef0ef53867bcef2de4a74fcd98bad48488d503fa0f7e4123d1b60e6404516ddcb9d8dab380cfd8f849806ba30dcb91accda51e8a406aa5a3bfdb6237cbf7535fe3007be06b2257f4aa6b53cc06ec0288c4703f5b2ab8814a959527ca173854a3c712957c",
        },
    };

    const res = await axios.get(`${url}/${resource}?${queryString}`, config);

    return res.data.data.map((item) => {
        const sliderThumb = item.attributes.images.data.map((imageData) => ({
            id: imageData.id,
            thumb: imageData.attributes.formats.medium.url,
            image: imageData.attributes.formats.medium.url,
        }));

        return {
            id: item.id,
            format: "slider",
            sliderThumb,
            title: item.attributes.name,
            artist: item.attributes.artists?.data[0]?.attributes?.name,
            medium: item.attributes.medium,
            dimensions: getItemDimensionsDescription(item),
            //ignore, just added to make UI not break
            categories: [],
            tags: [],
            body: [],
            date: "",
        };
    });
};

const BlogContainer = ({ resource, categoryId }) => {
    const { isLoading, isError, error, data } = useQuery({
        queryKey: [`${resource}-${categoryId}`],
        queryFn: () => fetchItems(resource, categoryId),
    });

    if (isLoading) {
        return <Loader height="50vh" margin="15vh auto" />;
    }

    if (isError) {
        return "Error!";
    }

    return (
        <div className="blog-area blog-masonry-area">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-12 text-center">
                        <BlogFilter categories={categories} />
                    </div> */}
                </div>
                <div className="row masonryGrid post-items-style1">
                    {/* <div className="col-sm-6 col-md-6 col-lg-4 resizer"></div> */}
                    {data &&
                        data.map((blog) => (
                            <div
                                key={blog.id}
                                className={`col-sm-6 col-md-6 col-lg-4 masonry-item ${blog.categories
                                    .map((cat) => slugify(cat))
                                    .join(" ")}`}
                            >
                                <BlogItem data={blog} resource={resource} />
                            </div>
                        ))}
                </div>
                {/* <div className="row">
                    <div className="col-lg-12 text-center">
                        <button className="btn-more">...Load More...</button>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

BlogContainer.propTypes = {
    page: PropTypes.string,
};

export default BlogContainer;
