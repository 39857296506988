import React from "react";

const SculptureDetailsSidebar = ({ data }) => {
    const available = data?.attributes?.availability == "Available";

    return (
        <div className="art-details-sidebar">
            <h1 style={{ display: "inline-block" }}>
                {data?.attributes?.name}
            </h1>
            <h6>
                <span style={{ fontWeight: "bold" }}>
                    {data?.attributes?.medium}
                </span>{" "}
                | {data?.attributes?.width}cm(W) x {data?.attributes?.height}(H)
            </h6>
            <h4 style={{ marginTop: "40px" }}>
                <span style={{ fontWeight: "bold" }}>
                    Total : A${data?.attributes?.price}
                </span>
                {!available && <span> (Not Available)</span>}
            </h4>
            <h6 style={{ marginTop: "20px" }}>
                {" "}
                {data?.attributes?.freeShippingAndReturns ? (
                    <span>Free shipping and returns</span>
                ) : (
                    ""
                )}
            </h6>
        </div>
    );
};

export default SculptureDetailsSidebar;
