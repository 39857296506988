import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Loader from "../../components/loader/index";
import ProductGallery from "../../components/product-gallery";
import SculptureDetailsSidebar from "./sculpture-details-sidebar";

const url = "https://gibu-art-be-f8dd06baa9af.herokuapp.com/api";

const fetchSculpture = async (sculptId) => {
    const queryObj = {
        populate: "*",
        sort: "rank:asc",
    };

    const queryString = qs.stringify(queryObj);

    const config = {
        headers: {
            Authorization:
                "Bearer 093f4cb2e504a184e2760b8b89dc19d7bc4053359dfc5ed393ade381ef0ef53867bcef2de4a74fcd98bad48488d503fa0f7e4123d1b60e6404516ddcb9d8dab380cfd8f849806ba30dcb91accda51e8a406aa5a3bfdb6237cbf7535fe3007be06b2257f4aa6b53cc06ec0288c4703f5b2ab8814a959527ca173854a3c712957c",
        },
    };

    const res = await axios.get(
        `${url}/sculptures/${sculptId}?${queryString}`,
        config
    );

    return res.data.data;
};

const SculptureDetails = ({ sculptId }) => {
    const { isLoading, isError, error, data } = useQuery({
        queryKey: ["sculpture", sculptId],
        queryFn: () => fetchSculpture(sculptId),
    });

    if (isLoading) {
        return <Loader height="60vh" margin="25vh auto" />;
    }

    if (isError) {
        return "Error!";
    }

    const images = data?.attributes?.images?.data?.map((item) => ({
        original: item.attributes.url,
        thumbnail: item.attributes.formats.thumbnail.url,
    }));

    return (
        <Container>
            <Row>
                <Col
                    md={{
                        size: 8,
                    }}
                >
                    <ProductGallery items={images} />
                </Col>
                <Col>
                    <SculptureDetailsSidebar data={data} />
                </Col>
            </Row>
        </Container>
    );
};

export default SculptureDetails;
