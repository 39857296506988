import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Loader from "../../components/loader/index";
import ProductGallery from "../../components/product-gallery";
import ArtDetailsSidebar from "./art-details-sidebar";

const url = "https://gibu-art-be-f8dd06baa9af.herokuapp.com/api";

const fetchArt = async (artId) => {
    const queryObj = {
        populate: "*",
    };

    const queryString = qs.stringify(queryObj);

    const config = {
        headers: {
            Authorization:
                "Bearer 093f4cb2e504a184e2760b8b89dc19d7bc4053359dfc5ed393ade381ef0ef53867bcef2de4a74fcd98bad48488d503fa0f7e4123d1b60e6404516ddcb9d8dab380cfd8f849806ba30dcb91accda51e8a406aa5a3bfdb6237cbf7535fe3007be06b2257f4aa6b53cc06ec0288c4703f5b2ab8814a959527ca173854a3c712957c",
        },
    };

    const res = await axios.get(`${url}/arts/${artId}?${queryString}`, config);

    return res.data.data;
};

const ArtDetails = ({ artId }) => {
    const { isLoading, isError, error, data } = useQuery({
        queryKey: ["art", artId],
        queryFn: () => fetchArt(artId),
    });

    if (isLoading) {
        return <Loader height="65vh" margin="30vh auto" />;
    }

    if (isError) {
        return "Error!";
    }

    const images = data?.attributes?.images?.data?.map((item) => ({
        original: item.attributes.url,
        thumbnail: item.attributes.formats.thumbnail.url,
    }));

    const embeddedUrl = data?.attributes?.embeddedVideoUrls;

    return (
        <Container>
            <Row>
                <Col md="8" sm="12">
                    <ProductGallery items={images} />
                </Col>
                <Col>
                    <ArtDetailsSidebar data={data} />
                </Col>
            </Row>
            {embeddedUrl && (
                <Row>
                    <div
                        style={{
                            marginTop: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <iframe
                            width="853"
                            height="480"
                            src={embeddedUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded Video"
                        />
                    </div>
                </Row>
            )}
        </Container>
    );
};

export default ArtDetails;
