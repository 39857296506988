import React from "react";
import { useParams } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ArtDetails from "../containers/art-details";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";

const Art = () => {
    const pathParams = useParams();

    return (
        <React.Fragment>
            <Layout>
                <SEO title="GIBU || Art" />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content art-details-main">
                        <ArtDetails artId={pathParams.artId} />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Art;
