import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Autoplay]);

const BlogItem = ({ data, resource }) => {
    const detailUrl = process.env.PUBLIC_URL + `/${resource}/${data.id}`;

    return (
        <div className="post-item">
            {data?.format === "slider" && (
                <div className="thumb">
                    <Swiper
                        className="post-gallery"
                        spaceBetween={0}
                        slidesPerView={1}
                        loop
                        autoplay={data.sliderThumb.length > 1}
                    >
                        {data.sliderThumb &&
                            data.sliderThumb.map((single, key) => {
                                return (
                                    <SwiperSlide
                                        key={key}
                                        style={{
                                            height: "auto",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Link to={detailUrl}>
                                            <img
                                                key={key}
                                                src={single.thumb}
                                                alt="Art"
                                                style={{
                                                    height: "100%",
                                                    minHeight: 555,
                                                }}
                                            />
                                        </Link>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
            )}
            {data?.format === "thumbnail" && (
                <div className="thumb">
                    <Link
                        to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/${data.media.thumb}`}
                            alt="Alexis-Blog"
                        />
                    </Link>
                </div>
            )}
            {data?.format === "audio" && (
                <div className="post-audio">
                    <div className="embed-responsive">
                        <iframe title="title" src={data.audioSrc}></iframe>
                    </div>
                </div>
            )}
            {data?.format === "quote" && (
                <div className="thumb">
                    <img
                        src={`${process.env.PUBLIC_URL}/${data.media.thumb}`}
                        alt="Alexis-Blog"
                    />
                    <div className="overlay-content">
                        <div className="quotes">
                            <div className="icon">
                                <i className="icon_quotations"></i>
                            </div>
                            <p>{data.quoteContent}</p>
                            <div className="name">- Chirs Ivan -</div>
                        </div>
                    </div>
                </div>
            )}
            <div className="content">
                <div className="meta">
                    {data.artist && (
                        <React.Fragment>
                            <Link className="post-date" to="/">
                                {data.artist}
                            </Link>
                            <span>|</span>
                        </React.Fragment>
                    )}
                    {data.dimensions && (
                        <React.Fragment>
                            <span className="author">{data.dimensions}</span>
                            <span>|</span>
                        </React.Fragment>
                    )}
                    {data.medium && <span>{data.medium}</span>}
                </div>
                <h4 className="title">
                    <Link to={detailUrl}>{data.title}</Link>
                </h4>
                {/* <Link
                    className="btn-link"
                    to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}
                >
                    Continue
                </Link> */}
            </div>
        </div>
    );
};

BlogItem.propTypes = {
    data: PropTypes.object,
};

export default BlogItem;
