import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
} from "reactstrap";
import Logo from "../logo";

function NavBar(args) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar expand="lg" container="fluid" full class>
                <NavbarBrand tag={RRNavLink} to="/">
                    <Logo image={`${process.env.PUBLIC_URL}/img/logo.png`} />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/all"}
                            >
                                ALL ART
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/sold"}
                            >
                                SOLD ART
                            </NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={
                                    process.env.PUBLIC_URL + "/curator-selected"
                                }
                            >
                                CURATOR SELECTED
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/prints"}
                            >
                                PRINTS
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/sculptures"}
                            >
                                SCULPTURES
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/about"}
                            >
                                ABOUT
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                tag={RRNavLink}
                                to={process.env.PUBLIC_URL + "/locations"}
                            >
                                LOCATIONS
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default NavBar;
