import AOS from "aos";
import "aos/dist/aos.css";
import "lightgallery.js/dist/css/lightgallery.css";
import { useEffect } from "react";
import "react-modal-video/scss/modal-video.scss";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
// CSS File Here
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./assets/css/elegantIcons.css";
import "./assets/css/icofont.css";
import "./assets/scss/style.scss";
import NavScrollTop from "./components/nav-scroll-top";
import AboutPage from "./pages/about";
import Art from "./pages/art-page";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import HomePage from "./pages/index";
import Portfolio from "./pages/portfolio";
import Sculpture from "./pages/sculpture-page";
import VideoGallery from "./pages/video-gallery";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogDetails from "./templates/blog-details";
import BlogTag from "./templates/blog-tag";
import PortfolioDetails from "./templates/portfolio-details";

// Create a client
const oneHourInMs = 1000 * 60 * 60 * 1;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: oneHourInMs,
        },
    },
});

const App = () => {
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <NavScrollTop>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            exact
                            component={HomePage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/about"}`}
                            component={AboutPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/portfolio"}`}
                            component={Portfolio}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/video-gallery"
                            }`}
                            component={VideoGallery}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/portfolio-details/:id"
                            }`}
                            component={PortfolioDetails}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/all"}`}
                            render={(props) => (
                                <Blog
                                    {...props}
                                    title="All Art"
                                    subtitle="Explore"
                                    categoryId={1}
                                    resource="arts"
                                />
                            )}
                        />
                        <Route exact path="/props-through-render" />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/sold"}`}
                            render={(props) => (
                                <Blog
                                    {...props}
                                    title="Sold Art"
                                    subtitle="Explore"
                                    categoryId={2}
                                    resource="arts"
                                />
                            )}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/curator-selected"
                            }`}
                            render={(props) => (
                                <Blog
                                    {...props}
                                    title="Curator Selected Art"
                                    subtitle="Explore"
                                    categoryId={3}
                                    resource="arts"
                                />
                            )}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/prints"}`}
                            render={(props) => (
                                <Blog
                                    {...props}
                                    title="Prints Available"
                                    subtitle="Explore"
                                    categoryId={4}
                                    resource="arts"
                                />
                            )}
                        />
                        <Route
                            exact
                            path={`${
                                process.env.PUBLIC_URL + "/sculptures/:sculptId"
                            }`}
                            // path="http://localhost:3000/art"
                            // path={`${process.env.PUBLIC_URL + "/art/:artId"}`}
                            component={Sculpture}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/sculptures"}`}
                            render={(props) => (
                                <Blog
                                    {...props}
                                    title="Scupltures"
                                    subtitle="Explore"
                                    categoryId={4}
                                    resource="sculptures"
                                />
                            )}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/arts/:artId"}`}
                            // path="http://localhost:3000/art"
                            // path={`${process.env.PUBLIC_URL + "/art/:artId"}`}
                            component={Art}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                            component={BlogTag}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                            component={BlogDate}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/author/:author"
                            }`}
                            component={BlogAuthor}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-details/:id"
                            }`}
                            component={BlogDetails}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/locations"}`}
                            component={Contact}
                        />
                    </Switch>
                </NavScrollTop>
            </Router>
        </QueryClientProvider>
    );
};

export default App;
