import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import React from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Intro from "../../../components/intro/index";
import Loader from "../../../components/loader/index";

const url = "https://gibu-art-be-f8dd06baa9af.herokuapp.com/api";

const fetchItems = async () => {
    const queryObj = {
        populate: "*",
        sort: "rank:asc",
    };

    const queryString = qs.stringify(queryObj);

    const config = {
        headers: {
            Authorization:
                "Bearer 093f4cb2e504a184e2760b8b89dc19d7bc4053359dfc5ed393ade381ef0ef53867bcef2de4a74fcd98bad48488d503fa0f7e4123d1b60e6404516ddcb9d8dab380cfd8f849806ba30dcb91accda51e8a406aa5a3bfdb6237cbf7535fe3007be06b2257f4aa6b53cc06ec0288c4703f5b2ab8814a959527ca173854a3c712957c",
        },
    };

    const res = await axios.get(`${url}/slider-items?${queryString}`, config);

    return res.data.data.map((item) => {
        return {
            id: item.id,
            backgroundImage: item.attributes?.image?.data?.attributes?.url,
            title: item.attributes?.title,
            subTitle: item.attributes?.subtitle,
            desc: item.attributes?.description,
            buttonText: item.attributes?.linkText,
            linkUrl: item.attributes?.linkUrl,
        };
    });
};

SwiperCore.use([Pagination]);
const IntroContainer = () => {
    const { isLoading, isError, error, data } = useQuery({
        queryKey: ["slider-data"],
        queryFn: () => fetchItems(),
    });

    if (isLoading) {
        return <Loader height="70vh" margin="35vh auto" />;
    }

    if (isError) {
        return "Error!";
    }

    const swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: { clickable: true },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
    };

    return (
        <div className="intro-slider-wrap">
            <Swiper effect="fade" className="intro-slider" {...swiperOption}>
                {data &&
                    data.map((single, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <Intro data={single} key={key} />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};

export default IntroContainer;
