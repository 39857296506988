import { Link } from "react-router-dom";
import FooterLogo from "../../components/footer-logo";

const Footer = () => {
    return (
        <div style={{ marginTop: "60px" }}>
            <div className="container-fluid ack-country-container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="widget-ack-country">
                            <p>
                                <span>ACKNOWLEDGMENT OF COUNTRY</span>
                            </p>
                            <p>
                                We Acknowledge and pay our respect to the
                                traditional owners of the land we live and work
                                on. We pay respect to their elders past and
                                present and extend to all Aboriginal and Torres
                                Strait Islander peoples today. This land always
                                was, and always will be, Aboriginal land.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer-area reveal-footer border-top-style">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer-content">
                                <div className="widget-item links-place-holder">
                                    <div className="widget-footer-nav">
                                        <nav>
                                            <ul>
                                                <li>
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/"
                                                        }
                                                    >
                                                        term &amp; condition
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/"
                                                        }
                                                    >
                                                        policy
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/"
                                                        }
                                                    >
                                                        map
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                {/* <div className="links-place-holder widget-item"></div> */}
                                <div className="widget-item text-center about-widget-container">
                                    <div className="about-widget">
                                        <FooterLogo
                                            image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                        />
                                    </div>
                                    <div className="widget-copyright">
                                        <p>
                                            © {new Date().getFullYear()}{" "}
                                            <span>GIBU</span>. Made with{" "}
                                            <i className="icofont-heart-alt"></i>{" "}
                                            by Initiative Inc.
                                        </p>
                                    </div>
                                </div>
                                <div className="widget-item social-widget-container">
                                    <ul className="widget-social">
                                        <li className="social-text">
                                            <span>follow us on social</span>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="social_twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="social_facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="social_instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
