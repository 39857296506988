import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import qs from "qs";
import React from "react";
import BlogItem from "../components/blog/blog-item";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import IntroContainer from "../containers/home/intro/index.jsx";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import { getItemDimensionsDescription } from "../utils";

const url = "https://gibu-art-be-f8dd06baa9af.herokuapp.com/api";

const fetchHomePage = async () => {
    const queryObj = {
        populate: "deep",
    };

    const queryString = qs.stringify(queryObj);

    const config = {
        headers: {
            Authorization:
                "Bearer 093f4cb2e504a184e2760b8b89dc19d7bc4053359dfc5ed393ade381ef0ef53867bcef2de4a74fcd98bad48488d503fa0f7e4123d1b60e6404516ddcb9d8dab380cfd8f849806ba30dcb91accda51e8a406aa5a3bfdb6237cbf7535fe3007be06b2257f4aa6b53cc06ec0288c4703f5b2ab8814a959527ca173854a3c712957c",
        },
    };

    const res = await axios.get(`${url}/home-page?${queryString}`, config);

    const homePageItems = res.data?.data?.attributes?.displayItems?.map(
        (item) => {
            const resource = item["__component"].split(".")[1];

            if (item[resource].data) {
                const sliderThumb = item[
                    resource
                ].data.attributes.images.data.map((imageData) => ({
                    id: imageData.id,
                    thumb: imageData.attributes.formats.medium.url,
                    image: imageData.attributes.formats.medium.url,
                }));

                return {
                    id: item[resource].data.id,
                    format: "slider",
                    sliderThumb,
                    title: item[resource].data.attributes.name,
                    artist:
                        item[resource].data.attributes.artists?.data[0]
                            ?.attributes?.name,
                    medium: item[resource].data.attributes.medium,
                    dimensions: getItemDimensionsDescription(
                        item[resource].data
                    ),
                    //ignore, just added to make UI not break
                    categories: [],
                    tags: [],
                    body: [],
                    date: "",
                    resource,
                };
            }
        }
    );

    return homePageItems.filter((item) => item !== undefined);
};

const HomePage = () => {
    const { isLoading, isError, error, data } = useQuery({
        queryKey: [`homePage`],
        queryFn: () => fetchHomePage(),
    });

    const displayItems = !isLoading && !isError;

    return (
        <React.Fragment>
            <Layout>
                <SEO title="GIBU Art Gallery" />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <IntroContainer />
                    <div className="main-content">
                        {/* <QuteContainer /> */}
                        {/* <PortfolioContainer /> */}
                        <div
                            className="blog-area blog-masonry-area"
                            style={{ marginTop: "40px" }}
                        >
                            <div className="container">
                                <div className="row masonryGrid post-items-style1">
                                    {/* <div className="col-sm-6 col-md-6 col-lg-4 resizer"></div> */}
                                    {data &&
                                        data.map((blog) => (
                                            <div
                                                key={blog.id}
                                                className={`col-sm-6 col-md-6 col-lg-4 masonry-item ${blog.categories
                                                    .map((cat) => slugify(cat))
                                                    .join(" ")}`}
                                            >
                                                <BlogItem
                                                    data={blog}
                                                    resource={`${blog.resource}s`}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
