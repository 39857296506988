import { CSSProperties } from "react";
import BounceLoader from "react-spinners/BounceLoader";

const TriangleLoader = ({ height, margin }) => {
    const override: CSSProperties = {
        display: "block",
        margin,
        borderColor: "red",
    };

    return (
        <div style={{ height }}>
            <BounceLoader
                color="#996600"
                loading
                cssOverride={override}
                size={80}
                aria-label="Loading"
                data-testid="loader"
            />
        </div>
    );
};

export default TriangleLoader;
